import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled } from '@mui/material/styles';
import {
  Card, CardHeader, Avatar, CardMedia, IconButton,
  CardContent, CardActions, Box, Collapse, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, Button, Stack, Paper, Typography, Slide, CircularProgress,
  Fade, Modal, Backdrop, Stepper, Step, StepLabel, StepContent,
  colors
} from '@mui/material';

import {
  Man, Woman, Share, ViewInAr, ThreeDRotation,
  WarningAmberOutlined, Folder, CheckOutlined, Close,
  ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded,
  BorderStyle, ExpandMore,
} from '@mui/icons-material';
import Sheet from '@mui/joy/Sheet'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIUrl, APIMainRequestPOST, APIMainRequestGET } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import { DateAndTime, DateOnly, TimeOnly, DateAndTineNoUI } from '../Manager/UnixTimestampToDate';
import shadows from '@mui/material/styles/shadows';
import lastSizing from '../Assets/lastSizing.png'

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  backgroundColor: '#f1f1f1',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  margin: 5,
  color: theme.palette.text.secondary,
}));


const DetailsMerchantLasts = ({ whichProduct, closeModalRefreshList }) => {

  const [loading, setLoading] = React.useState(false);


  const [getLastCatDetail, setLastCatDetail] = useState()

  const [openModal, setOpenModal] = React.useState(false);
  const [getRawFile, setRawFile] = useState()

  const [getFile, setFile] = useState()
  const [getFileLink, setFileLink] = useState()
  const [getFileName, setFileName] = useState()
  const [getFileType, setFileType] = useState()

  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);

  const [getWhichProd, setWhichProd] = useState({})
  const [getOurProductsList, setOurProductsList] = useState()
  const [getProductID, setProductID] = useState()
  const [getLastList, setLastList] = useState([])

  // const shearchProdId = (id) => {

  //   // const setReturn = getOurProductsList.find((prodId) => `${prodId.id}` === `${id}`) !== undefined
  //   // const setReturn = getOurProductsList.some((prodId) => `${prodId.id}` === `${id}`)
  //   const setReturn = getOurProductsList.filter((prodId) => `${prodId.id}` === `${id}`)
  //   console.log('DetailsMerchantLasts -> shearchProdId -> setReturn : ' + JSON.stringify(setReturn, null, 2))
  //   return setReturn

  // }

  const ImageProducts = (product) => {
    const { image, title } = product;
    const imgLink = image !== null ? image.src : 'https://picsum.photos/200'
    const imgAlt = image !== null ? image.alt : title
    return (
        <>
            <CardMedia
                component="img"
                sx={{ width: '7vw', height: '7vw' }}
                image={imgLink}
                alt={imgAlt}
            />
        </>
    )
}

  const ourProductsList = (prodID) => {
    const fakeParams = {
      perPage: 0,
    }
    const merchantID = localStorage.getItem('merchantID')
    console.log('DetailsMerchantLasts -> ourProductsList -> Our Products -> merchantID : ' + merchantID + ' - Product ID : ' + prodID)
    const makeProdRequest = `${APIUrl}/merchants/product/last/${merchantID}/${prodID}`
    APIMainRequestGET(makeProdRequest, fakeParams).then(function (response) {
      return response.getData
    }).then(function (myJson) {
      console.log('DetailsMerchantLasts -> ourProductsList -> getOurProductsList : ' + JSON.stringify(myJson.product, null, 2))
      setOurProductsList(myJson.product)
      setLastList(myJson.product.lasts)


    }).finally(() => {
      console.log('DetailsMerchantLasts -> ourProductsList -> getOurProductsList -> Finally ->')

    })
  }




  const displayLast = () => {
    return (

      <>
        <Box sx={{ bgcolor: red[700] }}>
          <Stack direction="column" spacing={1} >
            {
              getLastList.map((lastItem) =>
                <>
                  {/* <Stack direction="column" spacing={1} > */}
                  <Stack direction="row" spacing={1} >
                    <Item sx={{ width: '100%' }}>
                      <Typography> {getOurProductsList.name}</Typography>
                    </Item>
                    <Item sx={{ width: '100%' }}>
                      <StlViewer
                        // width={300}
                        // height='70hw'
                        groundColor='rgb(155, 155, 155)'
                        objectColor='rgb(50, 255, 255)'
                        skyboxColor='rgb(225, 225, 225)'
                        gridLineColor='rgb(0, 0, 0)'
                        lightColor='rgb(255, 255, 255)'
                        orbitControls
                        shadows
                        url={lastItem.linkPly !== "" ? lastItem.linkPly
                          : "https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl"}
                      />
                    </Item>
                  </Stack>
                  {/* </Stack> */}

                </>


              )
            }
          </Stack>
        </Box>
      </>


    )

  }

  useEffect(() => {
    console.log('DetailsMerchantLasts -> useEffect');
    console.log('DetailsMerchantLasts -> useEffect -> ID :' + JSON.stringify(whichProduct, null, 2));
    console.log('DetailsMerchantLasts -> useEffect -> ID :' + whichProduct.id);
    setWhichProd(whichProduct)
    setProductID(whichProduct.id)
    ourProductsList(whichProduct.id)

    // console.log('DetailsMerchantLasts -> useEffect -> Which products lasts :' + JSON.stringify(whichProduct, null, 2));

  }, []);

  return (
    // <Paper sx={styleAddLast}>
    <Box sx={{ flexDirection: 'row', minWidth: 500 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <IconButton aria-label="settings">
        <Close onClick={() => { closeModalRefreshList() }} />
      </IconButton>
      {loading === true ? <div><CircularProgress color="inherit" /></div> :
        <>
          <Typography>Product details : {getWhichProd.title}</Typography>
          <Typography>Product ID : {getWhichProd.id}</Typography>
          {ImageProducts(getWhichProd)}
          {/* <Typography>{ getWhichProd.image.alt }</Typography> */}
          {/* <CardMedia
          component="img"
          sx={{ width: '7vw', height: '7vw' }}
          image={getWhichProd.image}
          alt={getWhichProd.image}
        /> */}
        </>
      }
      {

        displayLast()
      }
    </Box>
    // </Paper>
  );
};

const styleAddLast = {
  position: 'absolute',
  top: '5%',
  left: '25%',
  // transform: 'translate(-50%, -50%)',
  width: '50%',
  minWidth: 500,
  height: '88%',
  bgcolor: '#ffffff',
  shadows: 'none',

  overflow: 'auto',
};

export default DetailsMerchantLasts;