import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled, useTheme, alpha } from '@mui/material/styles';
import clsx from 'clsx';
import {
    List, ListItem, ListItemButton, ListItemIcon, ListSubheader, ListItemAvatar, TextField, Box, MenuItem, Grid, useMediaQuery,
    FormControl, Button, Stack, Paper, Typography, ListItemText, Divider, Switch, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar,
    Fade, Modal, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    colors, Collapse, IconButton, Badge
} from '@mui/material';

import {
    SimpleTreeView, TreeItem, RichTreeView, unstable_useTreeItem2, treeItemClasses, TreeViewProvider,
    TreeItem2Content, TreeItem2IconContainer, TreeItem2GroupTransition, TreeItem2Label, TreeItem2Root, TreeItem2Checkbox,
    TreeItem2Icon, TreeItem2Provider, useTreeViewApiRef, TreeItemContent
} from '@mui/x-tree-view'
import { unstable_useTreeItem2 as useTreeItem } from '@mui/x-tree-view/';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';

import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view';

import {
    WarningAmberOutlined, Close, Inbox, Drafts, TaskAltOutlined, CancelOutlined, StarBorder,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded, ExpandMore, ExpandLess,
    Delete, Add, Save, Cancel, Edit, Mail, ArrowRight, ArrowDropDown, SupervisorAccount,
    BorderColor, Info, Label, TravelExplore, Straighten, Send, Folder
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET, APIMainRequestDELETE, APIMainRequestPATCH } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import { bool } from 'prop-types';
import { DateAndTime, DateOnly, TimeOnly } from '../Manager/UnixTimestampToDate';


const FunFactManager = () => {

    const apiRef = useTreeViewApiRef();

    const [getFunFact, setFunFact] = useState([])

    const [getAddFunFact, setAddFunFact] = useState(false)

    const [whichFunFactelected, setWhichFunFactelected] = useState('')

    const getAllFunFact = () => {
        const settingsParams = {
            perPage: 0,
        }
        console.log('FunFactManager -> getFunFactList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = '/funfacts/list'
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FunFactManager -> getFunFactList  : ' + JSON.stringify(myJson, null, 2))

            setFunFact(myJson)

        }).finally(() => {
            console.log('FunFactManager -> getFunFactList -> Finally : ')


        })
    }

    useEffect(() => {
        console.log('FunFactManager -> useEffect');

        getAllFunFact()

    }, []);

    const [getAddTitleValue, setAddTitleValue] = useState('')
    const [getAddDescriptionValue, setAddDescriptionValue] = useState('')

    const handleOnChangeFunFactTitle = (e) => {
        const getValue = e.target.value
        console.log('FunFactManager -> handleOnChangeFunFactTitle -> getValue : ' + getValue)
        setAddTitleValue(getValue)
    }

    const handleOnChangeFunFactDescription = e => {
        const getValue = e.target.value
        console.log('FunFactManager -> handleOnChangeFunFactDescription -> getValue : ' + getValue)
        setAddDescriptionValue(getValue)
    }

    

    const addValue = () => {
        const setParams = {
            title: getAddTitleValue,
            description: getAddDescriptionValue
        }

        console.log('FunFactManager -> getFunFactList -> settingsParams : ' + JSON.stringify(setParams, null, 2))

        APIMainRequestPOST('/funfacts/add', setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FunFactManager -> getFunFactList  : ' + JSON.stringify(myJson, null, 2))

            setFunFact(myJson)

        }).finally(() => {
            console.log('FunFactManager -> getFunFactList -> Finally : ')
            getAllFunFact()
            setAddTitleValue('')
            setAddDescriptionValue('')
        })
    }
    
    const handleAddFunFactElem = () => {
        console.log('FunFactManager -> handleAddFunFactElem -> ')
        setAddFunFact(true)
    }

    const handleUnselectElem = () => {
        getAddFunFact === true ? setAddFunFact(false) : setAddFunFact(false)
        console.log('FunFactManager -> handleUnselectElem -> getAddFunFact : ' + getAddFunFact)
    }

    const handleValidateNewElem = () => {
        console.log('FunFactManager -> handleValidateNewElem ')
        handleUnselectElem()
        addValue()
    }

    const deleteValue = id => {
        
        console.log('FunFactManager -> deleteValue -> whichOne : ' + id)
        
        const initParams = { value: 0 }
        console.log('FunFactManager -> deleteValue -> settingsParams : ' + JSON.stringify(initParams, null, 2))
        
        APIMainRequestDELETE(`/funfacts/delete/${id}`, initParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FunFactManager -> deleteValue  : ' + JSON.stringify(myJson, null, 2))

            setFunFact(myJson)

        }).finally(() => {
            console.log('FunFactManager -> deleteValue -> Finally : ')
            getAllFunFact()
            setWhichFunFactelected('')
        })
    }

    const [getUpdateFunFact, setUpdateFunFact] = useState(0)

    const handleUnselectUpdateElem = () => {

        getUpdateFunFact !== 0 ? setUpdateFunFact(0) : setUpdateFunFact(0)
        console.log('FunFactManager -> handleUnselectUpdateElem -> getUpdateFunFact : ' + getUpdateFunFact)
        setAddTitleValue('')
        setAddDescriptionValue('')
    }

    const [getUpdateTitleValue, setUpdateTitleValue] = useState('')
    const [getUpdateDescriptionValue, setUpdateDescriptionValue] = useState('')

    const handleUpdateFunFactTitle = (e) => {
        const getValue = e.target.value
        console.log('FunFactManager -> handleUpdateFunFactTitle -> getValue : ' + getValue)
        setUpdateTitleValue(getValue)
    }

    const handleUpdateFunFactDescription = e => {
        const getValue = e.target.value
        console.log('FunFactManager -> handleUpdateFunFactDescription -> getValue : ' + getValue)
        setUpdateDescriptionValue(getValue)
    }

    const updateValue = idItem => {
        console.log('FunFactManager -> updateValue -> idItem : ' + idItem)
        console.log(`FunFactManager -> updateValue -> newValue -> title : ${getUpdateTitleValue} - description : ${getUpdateDescriptionValue}`)

        const setParams = {
            title: getUpdateTitleValue,
            description: getUpdateDescriptionValue,
        }

        console.log('FunFactManager -> updateValue -> settingsParams : ' + JSON.stringify(setParams, null, 2))

        APIMainRequestPATCH(`/funfacts/update/${idItem}`, setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FunFactManager -> updateValue : ' + JSON.stringify(myJson, null, 2))

            setFunFact(myJson)

        }).finally(() => {
            console.log('FunFactManager -> updateValue -> Finally : ')
            getAllFunFact()
            handleUnselectUpdateElem()

        })

    }


    const handleItemEditeSelection = (Item) => {
        console.log('FunFactManager -> handleItemEditeSelection -> Item : ' + Item)
        setUpdateFunFact(Item._id)
        setUpdateTitleValue(Item.title)
        setUpdateDescriptionValue(Item.description)
    };



    const [openStd, setOpenStd] = React.useState(null);
    const handleClickStd = (id) => {
        console.log('FunFactManager -> handleClickStd -> id : ' + id)
        setOpenStd(id);
    };



    return (
        <Box sx={{ minHeight: 352 }}>

            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    getAddFunFact === true ?
                        <><Stack direction="column" spacing={1} sx={{ margin: 1 }} >
                            <TextField autoFocus
                                value={getAddTitleValue}
                                onChange={handleOnChangeFunFactTitle}
                                label='Title new FunFact'
                                id="outlined-size-small-title"
                                variant='outlined'
                                size="small"
                                color='primary'
                                sx={{ margin: 1 }} />
                            <TextField
                                value={getAddDescriptionValue}
                                onChange={handleOnChangeFunFactDescription}
                                label='Description new FunFact'
                                id="outlined-size-small-question"
                                variant='outlined'
                                size="small"
                                color='primary'
                                multiline
                                rows={4}
                                sx={{ margin: 1 }} />
                            <Stack direction="row" spacing={1} >
                                <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem()} sx={{ margin: 1 }}>Add</Button>
                                <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                            </Stack>
                        </Stack>
                        </>
                        :
                        <><br />
                            <Stack direction="row" spacing={1} sx={{ margin: 1 }} >

                                <Badge color="error" badgeContent={getFunFact.length}>
                                    <Button variant="outlined" startIcon={<Add />} onClick={() => handleAddFunFactElem()} size="small" sx={{ color: blue[700] }}>
                                        FunFact
                                    </Button>
                                </Badge>

                            </Stack>
                        </>
                }
            >
                {
                    getFunFact.map((item, index) =>
                        <>
                            <ListItem
                                secondaryAction={
                                    getUpdateFunFact !== item._id ?
                                        <>
                                            <Stack direction="row" spacing={2} >
                                                <IconButton>
                                                    <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemEditeSelection(item)} />
                                                </IconButton>
                                                <IconButton>
                                                    <Delete sx={{ color: red[700] }} onClick={() => deleteValue(item._id)} />
                                                </IconButton>

                                                {openStd !== index ? <IconButton><ExpandMore onClick={() => handleClickStd(index)} /></IconButton> : <IconButton><ExpandLess onClick={() => handleClickStd(null)} /></IconButton>}
                                            </Stack>
                                        </> : <></>
                                }
                            >
                                <ListItemAvatar><Avatar><TravelExplore /></Avatar></ListItemAvatar>
                                <ListItemText
                                    primary={
                                        getUpdateFunFact !== item._id ? item.title :
                                            <>
                                                <Stack direction="column" spacing={1} >
                                                    <TextField autoFocus
                                                        value={getUpdateTitleValue}
                                                        onChange={handleUpdateFunFactTitle}
                                                        label='Update Fun fact Title'
                                                        id="outlined-size-small-title-up"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        sx={{ margin: 1 }} />
                                                    <TextField
                                                        value={getUpdateDescriptionValue}
                                                        onChange={handleUpdateFunFactDescription}
                                                        label='Update Fun fact Description'
                                                        id="outlined-size-small-question-up"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        multiline
                                                        rows={4}
                                                        sx={{ margin: 1 }} />
                                                    <Stack direction="row" spacing={1} >
                                                        <Button variant="outlined" color='primary' onClick={() => updateValue(item._id)} sx={{ margin: 1 }}>Update</Button>
                                                        <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                    </Stack>
                                                </Stack>
                                            </>
                                    }
                                    secondary={getUpdateFunFact !== item._id ? <>Created : <DateOnly unixTimestamp={item.entryDate} /> - Updated : <DateOnly unixTimestamp={item.updateDate} /></> : ''}
                                />

                            </ListItem>
                            <Collapse in={openStd === index} timeout="auto" unmountOnExit sx={{ paddingLeft: 4 }}>
                                <Stack direction="column" spacing={1} >
                                    <Stack direction="row" spacing={1} >
                                        <Typography sx={{ minWidth: '100px' }} ><b>Title : </b></Typography><Typography >{item.title}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} >
                                        <Typography sx={{ minWidth: '100px' }} ><b>Description : </b></Typography><Typography >{item.description}</Typography>
                                    </Stack>
                                </Stack>
                            </Collapse>

                        </>
                    )
                }
            </List>
        </Box >
    );
}

export default FunFactManager;