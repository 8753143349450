import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled, useTheme, alpha } from '@mui/material/styles';
import clsx from 'clsx';
import {
    List, ListItem, ListItemButton, ListItemIcon, ListSubheader, ListItemAvatar, TextField, Box, MenuItem, Grid, useMediaQuery,
    FormControl, Button, Stack, Paper, Typography, ListItemText, Divider, Switch, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar,
    Fade, Modal, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    colors, Collapse, IconButton, Badge
} from '@mui/material';

import {
    SimpleTreeView, TreeItem, RichTreeView, unstable_useTreeItem2, treeItemClasses, TreeViewProvider,
    TreeItem2Content, TreeItem2IconContainer, TreeItem2GroupTransition, TreeItem2Label, TreeItem2Root, TreeItem2Checkbox,
    TreeItem2Icon, TreeItem2Provider, useTreeViewApiRef, TreeItemContent
} from '@mui/x-tree-view'
import { unstable_useTreeItem2 as useTreeItem } from '@mui/x-tree-view/';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';

import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view';

import {
    WarningAmberOutlined, Close, Inbox, Drafts, TaskAltOutlined, CancelOutlined, StarBorder,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded, ExpandMore, ExpandLess,
    Delete, Add, Save, Cancel, Edit, Mail, ArrowRight, ArrowDropDown, SupervisorAccount,
    BorderColor, Info, Label, TravelExplore, Straighten, Send, Folder
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET, APIMainRequestDELETE, APIMainRequestPATCH } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import { bool } from 'prop-types';
import { DateAndTime, DateOnly, TimeOnly } from '../Manager/UnixTimestampToDate';

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: 'var(--tree-view-color)',
    },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: Label,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0.5,
                            pr: 0,
                        }}
                    >
                        <Box component={Label} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

const FaqsManager = () => {

    const apiRef = useTreeViewApiRef();

    const [getFaqs, setFaqs] = useState([])

    const [getAddFaqs, setAddFaqs] = useState(false)

    const [whichFaqSelected, setWhichFaqSelected] = useState('')

    const getAllFaqs = () => {
        const settingsParams = {
            perPage: 0,
        }
        console.log('FaqsManager -> getFaqsList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = '/faq/list'
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FaqsManager -> getFaqsList  : ' + JSON.stringify(myJson, null, 2))

            setFaqs(myJson)

        }).finally(() => {
            console.log('FaqsManager -> getFaqsList -> Finally : ')


        })
    }

    useEffect(() => {
        console.log('FaqsManager -> useEffect');

        getAllFaqs()

    }, []);

    const [getAddTitleValue, setAddTitleValue] = useState('')
    const [getAddQuestionValue, setAddQuestionValue] = useState('')
    const [getAddAnswerValue, setAddAnswerValue] = useState('')

    const handleOnChangeFaqsTitle = (e) => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleOnChangeFaqsTitle -> getValue : ' + getValue)
        setAddTitleValue(getValue)
    }

    const handleOnChangeFaqsQuestion = e => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleOnChangeFaqsQuestion -> getValue : ' + getValue)
        setAddQuestionValue(getValue)
    }

    const handleOnChangeFaqsAnswer = (e) => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleOnChangeFaqsAnswer -> getValue : ' + getValue)
        setAddAnswerValue(getValue)
    }

    const addValue = () => {
        const setParams = {
            title: getAddTitleValue,
            question: getAddQuestionValue,
            answer: getAddAnswerValue,
        }

        console.log('FaqsManager -> getFaqsList -> settingsParams : ' + JSON.stringify(setParams, null, 2))

        APIMainRequestPOST('/faq/add', setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FaqsManager -> getFaqsList  : ' + JSON.stringify(myJson, null, 2))

            setFaqs(myJson)

        }).finally(() => {
            console.log('FaqsManager -> getFaqsList -> Finally : ')
            getAllFaqs()
            setAddTitleValue('')
            setAddQuestionValue('')
            setAddAnswerValue('')
        })
    }
    
    const handleAddFaqsElem = () => {
        console.log('FaqsManager -> handleAddFaqsElem -> ')
        setAddFaqs(true)
    }

    const handleUnselectElem = () => {
        getAddFaqs === true ? setAddFaqs(false) : setAddFaqs(false)
        console.log('FaqsManager -> handleUnselectElem -> getAddFaqs : ' + getAddFaqs)
    }

    const handleValidateNewElem = () => {
        console.log('FaqsManager -> handleValidateNewElem ')
        handleUnselectElem()
        addValue()
    }

    const deleteValue = id => {
        
        console.log('FaqsManager -> deleteValue -> whichOne : ' + id)
        
        const initParams = { value: 0 }
        console.log('FaqsManager -> deleteValue -> settingsParams : ' + JSON.stringify(initParams, null, 2))
        
        APIMainRequestDELETE(`/faq/delete/${id}`, initParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FaqsManager -> deleteValue  : ' + JSON.stringify(myJson, null, 2))

            setFaqs(myJson)

        }).finally(() => {
            console.log('FaqsManager -> deleteValue -> Finally : ')
            getAllFaqs()
            setWhichFaqSelected('')
        })
    }

    const [getUpdateFAQS, setUpdateFAQS] = useState(0)

    const handleUnselectUpdateElem = () => {

        getUpdateFAQS !== 0 ? setUpdateFAQS(0) : setUpdateFAQS(0)
        console.log('FaqsManager -> handleUnselectUpdateElem -> getUpdateFAQS : ' + getUpdateFAQS)
        setAddTitleValue('')
        setAddQuestionValue('')
        setAddAnswerValue('')
    }

    const [getUpdateTitleValue, setUpdateTitleValue] = useState('')
    const [getUpdateQuestionValue, setUpdateQuestionValue] = useState('')
    const [getUpdateAnswerValue, setUpdateAnswerValue] = useState('')

    const handleUpdateFaqsTitle = (e) => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleUpdateFaqsTitle -> getValue : ' + getValue)
        setUpdateTitleValue(getValue)
    }

    const handleUpdateFaqsQuestion = e => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleUpdateFaqsQuestion -> getValue : ' + getValue)
        setUpdateQuestionValue(getValue)
    }

    const handleUpdateFaqsAnswer = (e) => {
        const getValue = e.target.value
        console.log('FaqsManager -> handleUpdateFaqsAnswer -> getValue : ' + getValue)
        setUpdateAnswerValue(getValue)
    }

    const updateValue = idItem => {
        console.log('FaqsManager -> updateValue -> idItem : ' + idItem)
        console.log(`FaqsManager -> updateValue -> newValue -> title : ${getUpdateTitleValue} - question : ${getUpdateQuestionValue} - answer : ${getUpdateAnswerValue}`)

        const setParams = {
            title: getUpdateTitleValue,
            question: getUpdateQuestionValue,
            answer: getUpdateAnswerValue,
        }

        console.log('FaqsManager -> updateValue -> settingsParams : ' + JSON.stringify(setParams, null, 2))

        APIMainRequestPATCH(`/faq/update/${idItem}`, setParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('FaqsManager -> updateValue : ' + JSON.stringify(myJson, null, 2))

            setFaqs(myJson)

        }).finally(() => {
            console.log('FaqsManager -> updateValue -> Finally : ')
            getAllFaqs()
            handleUnselectUpdateElem()

        })

    }


    const handleItemEditeSelection = (Item) => {
        console.log('FaqsManager -> handleItemEditeSelection -> Item : ' + Item)
        setUpdateFAQS(Item._id)
        setUpdateTitleValue(Item.title)
        setUpdateQuestionValue(Item.question)
        setUpdateAnswerValue(Item.answer)
    };



    const [openStd, setOpenStd] = React.useState(null);
    const handleClickStd = (id) => {
        console.log('FaqsManager -> handleClickStd -> id : ' + id)
        setOpenStd(id);
    };



    return (
        <Box sx={{ minHeight: 352 }}>

            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    getAddFaqs === true ?
                        <><Stack direction="column" spacing={1} sx={{ margin: 1 }} >
                            <TextField autoFocus
                                value={getAddTitleValue}
                                onChange={handleOnChangeFaqsTitle}
                                label='Title new Faqs'
                                id="outlined-size-small-title"
                                variant='outlined'
                                size="small"
                                color='primary'
                                sx={{ margin: 1 }} />
                            <TextField
                                value={getAddQuestionValue}
                                onChange={handleOnChangeFaqsQuestion}
                                label='Question new Faqs'
                                id="outlined-size-small-question"
                                variant='outlined'
                                size="small"
                                color='primary'
                                multiline
                                rows={4}
                                sx={{ margin: 1 }} />
                            <TextField
                                value={getAddAnswerValue}
                                onChange={handleOnChangeFaqsAnswer}
                                label='Question new Faqs'
                                id="outlined-size-small-answer"
                                variant='outlined'
                                size="small"
                                color='primary'
                                multiline
                                rows={4}
                                sx={{ margin: 1 }} />
                            <Stack direction="row" spacing={1} >
                                <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem()} sx={{ margin: 1 }}>Add</Button>
                                <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                            </Stack>
                        </Stack>
                        </>
                        :
                        <><br />
                            <Stack direction="row" spacing={1} sx={{ margin: 1 }} >

                                <Badge color="error" badgeContent={getFaqs.length}>
                                    <Button variant="outlined" startIcon={<Add />} onClick={() => handleAddFaqsElem()} size="small" sx={{ color: blue[700] }}>
                                        Faqs
                                    </Button>
                                </Badge>

                            </Stack>
                        </>
                }
            >
                {

                    getFaqs.map((item, index) =>
                        <>
                            <ListItem
                                secondaryAction={
                                    getUpdateFAQS !== item._id ?
                                        <>
                                            <Stack direction="row" spacing={2} >
                                                <IconButton>
                                                    <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemEditeSelection(item)} />
                                                </IconButton>
                                                <IconButton>
                                                    <Delete sx={{ color: red[700] }} onClick={() => deleteValue(item._id)} />
                                                </IconButton>

                                                {openStd !== index ? <IconButton><ExpandMore onClick={() => handleClickStd(index)} /></IconButton> : <IconButton><ExpandLess onClick={() => handleClickStd(null)} /></IconButton>}
                                            </Stack>
                                        </> : <></>
                                }
                            >
                                <ListItemAvatar><Avatar><TravelExplore /></Avatar></ListItemAvatar>
                                <ListItemText
                                    primary={
                                        getUpdateFAQS !== item._id ? item.title :
                                            <>
                                                <Stack direction="column" spacing={1} >
                                                    <TextField autoFocus
                                                        value={getUpdateTitleValue}
                                                        onChange={handleUpdateFaqsTitle}
                                                        label='Update FAQ Title'
                                                        id="outlined-size-small-title-up"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        sx={{ margin: 1 }} />
                                                    <TextField
                                                        value={getUpdateQuestionValue}
                                                        onChange={handleUpdateFaqsQuestion}
                                                        label='Update FAQ Question'
                                                        id="outlined-size-small-question-up"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        multiline
                                                        rows={4}
                                                        sx={{ margin: 1 }} />
                                                    <TextField
                                                        value={getUpdateAnswerValue}
                                                        onChange={handleUpdateFaqsAnswer}
                                                        label='Update FAQ Answer'
                                                        id="outlined-size-small-answer-up"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        multiline
                                                        rows={4}
                                                        sx={{ margin: 1 }} />
                                                    <Stack direction="row" spacing={1} >
                                                        <Button variant="outlined" color='primary' onClick={() => updateValue(item._id)} sx={{ margin: 1 }}>Update</Button>
                                                        <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                    </Stack>
                                                </Stack>
                                            </>
                                    }
                                    secondary={getUpdateFAQS !== item._id ? <>Created : <DateOnly unixTimestamp={item.entryDate} /> - Updated : <DateOnly unixTimestamp={item.updateDate} /></> : ''}
                                />

                            </ListItem>
                            <Collapse in={openStd === index} timeout="auto" unmountOnExit sx={{ paddingLeft: 4 }}>
                                <Stack direction="column" spacing={1} >
                                    <Stack direction="row" spacing={1} >
                                        <Typography sx={{ minWidth: '100px' }} ><b>Title : </b></Typography><Typography >{item.title}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} >
                                        <Typography sx={{ minWidth: '100px' }} ><b>Question : </b></Typography><Typography >{item.question}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} >
                                        <Typography sx={{ minWidth: '100px' }} ><b>Answer : </b></Typography><Typography >{item.answer}</Typography>
                                    </Stack>
                                </Stack>
                            </Collapse>

                        </>
                    )
                }
            </List>
        </Box >
    );
}

export default FaqsManager;