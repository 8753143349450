import { Link, NavLink, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { ListItemText, ListItemIcon, ListItemButton, ListItem, Divider, List, Box, Drawer, Button, Typography } from '@mui/material';
import { Inbox, Mail, Menu, Logout, Login, Home, People, ViewInAr, Settings, ShowChart, Inventory, AccountBox, PriceCheck, Store, QuestionMark } from '@mui/icons-material';
import { blue, red } from "@mui/material/colors";
import Styles from "../Styles/Styles";

import { APIUrl, APIMainRequestGET, APIMainRequestPOST } from "../Manager/CommManager";
import { storeData, getData, removeData } from "../Manager/DataManager";

const NavBar = ({ getWitchPage }) => {
    const [connected, setConnected] = useState(true)
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
//// Original navBar list : ['Dashboard', 'Analytics', 'Products', 'Lasts', 'Plans', 'Account', 'FAQs', 'Users']
    const navBarListName = ['Dashboard', 'Products', 'Lasts', 'Plans', 'Account', 'FAQs']

    const navBarStruct = [
        { id: 0, name: 'Dashboard', icon: <Home /> },
        { id: 1, name: 'Analytics', icon: <ShowChart /> },
        { id: 2, name: 'Products', icon: <Store /> },
        { id: 3, name: 'Lasts', icon: <ViewInAr /> },
        { id: 4, name: 'Plans', icon: <PriceCheck /> },
        { id: 5, name: 'Account', icon: <AccountBox /> },
        { id: 6, name: 'FAQs', icon: <QuestionMark /> },
        { id: 7, name: 'Users', icon: <People /> },

    ]

    const navigate = useNavigate()

    const handleListItemClick = (witchSection, index) => {
        console.log('NavBar Action -> witch Pages : ' + witchSection)
        setSelectedIndex(index);
        selectSection(witchSection)

    };
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const selectSection = (witchSection) => {
        console.log('NavBar Action -> witch Pages : ' + witchSection)
        // setActive(witchSection)

        getWitchPage(witchSection)
    }

    useEffect(() => {
        console.log('NavBar -> useEffect -> Index : ' + selectedIndex);
        console.log('NavBar -> useEffect -> Current URL : ' + window.location.href);
        console.log('NavBar -> useEffect -> Current URL : ' + window.location.pathname);


        setConnected(true)

    }, []);

    const navBarItem = (index, title, icon) => {
        return (
            <ListItem key={index}>
                <ListItemButton
                    sx={selectedIndex === index ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                    onClick={() => { handleListItemClick(title, index) }} >

                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} />
                </ListItemButton>
            </ListItem>
        )
    }

    const returnItem = (index, text, icon) => {

        

        switch (index) {
            case 0:
                return <ListItem key={text}>
                    <ListItemButton
                        //href="/"
                        sx={selectedIndex === 0 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 0) }} >

                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 1:
                return <ListItem key={text}>
                    <ListItemButton //href="/analytics"
                        sx={selectedIndex === 1 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 1) }} >
                        <ListItemIcon>
                            <ShowChart />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 2:
                return <ListItem key={text} >
                    <ListItemButton //href="/products"
                        sx={selectedIndex === 2 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 2) }} >
                        <ListItemIcon>
                            <Store />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 3:
                return <ListItem key={text} >
                    <ListItemButton //href="/lasts"
                        sx={selectedIndex === 3 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 3) }} >
                        <ListItemIcon>
                            <ViewInAr />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 4:
                return <ListItem key={text}>
                    <ListItemButton //href="/plans"
                        sx={selectedIndex === 4 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 4) }} >
                        <ListItemIcon>
                            <PriceCheck />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 5:
                return <ListItem key={text} >
                    <ListItemButton //href="/account"
                        sx={selectedIndex === 5 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 5) }} >
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 6:
                return <ListItem key={text} >
                    <ListItemButton //href="/faqs"
                        sx={selectedIndex === 6 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 6) }} >
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            case 7:
                return <ListItem key={text} >
                    <ListItemButton //href="/users"
                        sx={selectedIndex === 7 ? Styles.Styles.AppItemsSelected : Styles.Styles.AppItems}
                        onClick={() => { handleListItemClick(text, 7) }} >
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
            default:
                return
        }
    }

    const matchingItems = navBarStruct.filter(item => navBarListName.includes(item.name));

    
    const DrawerList = (
        <Box sx={{ minWidth: 250, width: 250, height: '92vh' }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {
                    matchingItems.map((item) => (
                        navBarItem(item.id, item.name, item.icon)
                    ))
                }
            </List>
            <Divider />
            <List>
                <ListItemButton>
                    <ListItemIcon>
                        {connected ? <Logout /> : <Login />}
                    </ListItemIcon>
                    <ListItemText primary={connected ? 'Logout' : 'Login'} />
                </ListItemButton>
            </List>
        </Box >
    );

    return (
        <div>
            {DrawerList}
        </div>
    );
};


export default NavBar;