import { Link, NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {
    ListItemText, ListItemIcon, ListItemButton, ListItem, Divider, List, Box, Drawer, MenuItem,
    Button, AppBar, Toolbar, IconButton, Typography, Container, Avatar, Tooltip, Menu,
} from '@mui/material';
import { MenuOpen, Logout, Login, Adb } from '@mui/icons-material';
import { blue, grey, red } from "@mui/material/colors";
import Styles from "../Styles/Styles";
import logo from '../Assets/logo.png';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const UseQuery = () => new URLSearchParams(window.location.search)

const Header = () => {
    const [connected, setConnected] = useState(true)
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [currentPath, setCurrentPath] = React.useState();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [merchantName, setMerchantName] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    

    useEffect(function effectFunction() {
        console.log('Header -> useEffect -> Index : ' + selectedIndex);
        setCurrentPath(window.location.pathname)
        console.log('Header -> useEffect -> Current URL : ' + window.location.origin);
        console.log('Header -> useEffect -> Current URL #1 : ' + window.location.pathname);
        console.log('Header -> useEffect -> Current URL #2 : ' + window.location.href);
        console.log('Header -> useEffect -> Current URL #3 : ' + window.location.protocol);

        const query = UseQuery()
        const getShopParams = query.get('shop')
        
        const getStoreName = localStorage.getItem('localShop')
        const storeedName = getStoreName.split('.')

        console.log('Header -> useEffect -> Current Merchant name : ' + storeedName[0]);
        setMerchantName(storeedName[0])

        setConnected(true)

    }, []);

    return (
        <AppBar position="static" sx={{ bgcolor: '#FFFFFF', height: '8vh', boxShadow: 'none' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Avatar alt="3D Fitter" src={logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: grey[600],
                            textDecoration: 'none',
                        }}
                    >
                        Tailored Fit - {merchantName}
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuOpen />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu> */}
                    </Box>
                    {/* <Adb sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                    <Avatar alt="3D Fitter" src={logo} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))} */}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Camper" src={logo} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;