import * as React from 'react';
import { Avatar, Button, Link, TextField, Typography, Grid, Paper, FormControlLabel, Checkbox, Box, Stack } from '@mui/material';
import { LockOutlined, LocationCity } from '@mui/icons-material';
import { blue, grey, red } from '@mui/material/colors';


const ForgotPassword = () => {

    const paperStyle = { padding: 20, height: '60vh', width: '40vw', margin: "19px auto", backgroundColor: grey[50], borderRadius: 5 }
    const avatarStyle = { backgroundColor: '#D9D9D9' }
    const logoStyle = { backgroundColor: '#D9D9D9', margin: '10px 0', width: 110, height: 110 }

    const handleLogin = () => {
        console.log('Signin -> handleLogin')

    }

    const handleOnChange = e => {
        const getID = e.target.id
        const getValue = e.target.value
        console.log('Signin -> handleOnChange -> Value : ' + getValue + ' - Get ID : ' + getID)
    }

    return (
        <>
            <Grid align='center'>
                <Avatar style={avatarStyle}><LockOutlined style={{ color: '#002A57' }} /></Avatar>
                <h2>Signin</h2>
            </Grid>
            <TextField
                id="Username"
                label="Username"
                placeholder='Enter Your Username'
                sx={{ marginBottom: 4 }}
                fullWidth required
                onChange={handleOnChange} />



            <Button
                sx={{ bgcolor: blue[700], marginBottom: 4 }}
                type='submit'
                color='primary'
                variant="contained"
                fullWidth>Reset my password</Button>

        </>


    )
}

export default ForgotPassword