import logo from '../Assets/logo.png';
import leftFoot from '../Assets/leftFoot.png';
import rightFoot from '../Assets/rightFoot.png';
import shoesLastGrey from '../Assets/shoeLastGrey.png';
import '../Styles/App.css';
import axios from 'axios';
import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import { StlViewer } from "react-stl-viewer";
import { replace, useNavigate, useSearchParams, redirect } from 'react-router-dom';
import { APIMainRequestGET, APIMainRequestPOST, APIUrl } from '../Manager/CommManager.js';
import {
    ListItemText, ListItemIcon, ListItemButton, ListItem, Divider, List, Box, AccordionSummary, AccordionActions,
    Drawer, Button, ListSubheader, Collapse, Avatar, Accordion, AccordionDetails, Typography, Modal, Switch, FormControlLabel,
    Grid, Card, CardContent, CardHeader, CardMedia, CardActions, IconButton, Paper, Chip, Stack, Alert, Slide, ToggleButton, ToggleButtonGroup,
    Backdrop, Fade, CircularProgress, Autocomplete, TextField, Stepper, Step, StepLabel, StepContent, Checkbox, Radio, RadioGroup
} from '@mui/material';
import {
    Inbox, Mail, Menu, Logout, Login, Home, People, ViewInAr, Settings, ShowChart, Inventory,
    AccountBox, PriceCheck, Store, Send, ExpandLess, ExpandMore, StarBorder, Favorite, Share, MoreVert,
    Check, Clear, Close, WarningAmberOutlined, AttachFile, Straighten, Circle,
    BorderStyle, Done, TravelExplore, Label
} from '@mui/icons-material';

import { experimentalStyled as styled } from '@mui/material';

import { blue, green, grey, orange, purple, red, yellow } from "@mui/material/colors";
import Styles from "../Styles/Styles";
import parse from 'html-react-parser'

import { DropzoneArea } from 'material-ui-dropzone';

import { formatDate } from '../Manager/Utils.js';
import ModelViewer from '../Components/ModelViewer.js';
import SnackBarManager from '../Components/SnackBarManager.js';



const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: 'none'
    // color: theme.palette.text.secondary,
}));

const steps = [
    {
        label: 'Select file shoe last',
        description: `Drag and drop your file on the zone bellow`,
    },
    {
        label: 'Left or Right ?',
        description: 'Select which side for this last',
    },
    {
        label: 'A heel ?',
        description: 'If this shoe have a heel set the hight',
    },
    {
        label: 'Select which size standard you want use',
        description: `US Men, US Women, UK, Euro, Etc..`,
    },
    {
        label: 'Select the size for this last',
        description: `Select which size asiociate to this last and options related to you standard`,
    },
];

const standardSize = [
    { label: 'US Men', value: 'usm' },
    { label: 'US Women', value: 'usw' },
    { label: 'UK', value: 'uk' },
    { label: 'Euro', value: 'euro' }
];


const UploadMerchantLast = ({ whichProduct, closeModalRefreshList }) => {
    // const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [messageDisplayed, setMessageDisplayed] = useState('')
    const [messageTypeDisplayed, setMessageTypeDisplayed] = useState('')

    const [formValidation, setFromValidation] = useState(false)

    const [getStandard, setStandard] = useState([])
    const [getOneStandardValue, setOneStandardValue] = useState([])

    const [alignment, setAlignment] = React.useState(null);
    const handleChangeLeftRight = (event, newAlignment) => {
        console.log('Products -> handleChangeLeftRight -> newAlignment : ' + newAlignment);
        setAlignment(newAlignment);
    };

    const UseQuery = () => new URLSearchParams(window.location.search)
    const [getProductsList, setProductsList] = useState([])
    const [getInfoProduct, setInfoProduct] = useState([])

    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [imageProd, setImageProd] = useState()

    // STEPPER
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = (id) => {
        setActiveStep(id);
    };
    // SETPPER
    const [displayHightHeel, setDisplayHightHeel] = React.useState(false);
    const [hightHeel, setHightHeel] = React.useState(null);
    const [standardName, setStandardName] = React.useState(null);
    const [sizeList, setSizeList] = React.useState([]);
    const [widthList, setWidthList] = React.useState([]);

    const getHightHeel = (e) => {
        console.log('Set Heel Hight -> heelValue : ' + e.target.value)
        setHightHeel(e.target.value)
    }

    const getDisplayHightHeel = (e) => {
        const whichOne = e.target.value
        whichOne == "yes" ?
            setDisplayHightHeel(!displayHightHeel)
            :
            // setDisplayHightHeel(!displayHightHeel)
            setHightHeel(0)
    }

    const getStandardValue = (newValue) => {

        console.log('StandardManager -> standardName : ' + newValue.name)
        setStandardName(newValue.name)
        setSizeList(newValue.size)
        setWidthList(newValue.width)

    }

    const [sizeValue, setSizeValue] = React.useState(null);
    const [widthValue, setWidthValue] = React.useState(null);

    const [openMaxSizeModal, setOpenMaxSizeModal] = React.useState(false);
    const [openMessageModal, setOpenMessageModal] = React.useState('');
    const handleCloseMaxSizeModal = () => {
        setOpenMaxSizeModal(false)
        setFile(null)
    }


    // UPLOAD FILE
    const handleFileChange = (e) => {

        const file = e.target.files[0];
        console.log('Products -> handleDropzone -> ' + file)
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (['stl', 'ply', 'obj'].includes(fileExtension) && file) {

            const maxSizeInBytes = (1024 * 1024) * 10; // 10MB (exemple : you can adjust this to suit your needs)
            if (file.size > maxSizeInBytes) {
                setOpenMaxSizeModal(true)
                setOpenMessageModal('The max size autorized is 10 Mo')
            } else {
                setFile(file);
                setFileType(fileExtension);
                setFileName(file.name)
            }

        } else {
            setOpenMaxSizeModal(true)
            setOpenMessageModal('Unsupported file type!')
            setFile(null);
            setFileType(null);
            setFileName(null)
        }

        console.log('Products -> handleDropzone -> ' + file)

    };

    const handleSubmit = async (e) => {

        setLoading(true);

        e.preventDefault();

        if (!file) {
            alert("Please select a file first!");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        const merchantID = localStorage.getItem('merchantID')
        formData.append('containerName', merchantID);

        try {
            const response = await axios.post(`${APIUrl}/PFLasts/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("File uploaded successfully:", response.data.success);
            if (response.status === 200) {

                linkProducts()


                setLoading(false);
                setOpen(true)
                setMessageTypeDisplayed('success')
                setMessageDisplayed('Your last as been uploaded !')

            } else {
                setLoading(false);
                setOpen(true)
                setMessageTypeDisplayed('error')
                setMessageDisplayed('Error during your lasst uploaded process')

            }

            setTimeout(() => {
                setOpen(false)
                setMessageTypeDisplayed('')
                setMessageDisplayed('')
                handleDeleteStandard('all')
                closeModalRefreshList()
            }, 3000);
        } catch (error) {
            console.error("There was an error uploading the file:", error);
        }
    };

    const ImageProducts = (product) => {
        const { image } = product;
        console.log('UploadMerchantLast -> ImageProducts -> product : ' + product);
        const imgLink = image !== null ? image.src : 'https://picsum.photos/200'
        const imgAlt = image !== null ? image.alt : product.title

        return (
            <>
                <Avatar
                    sx={{ width: '7vw', height: '7vw' }}
                    src={imgLink}
                    alt={imgAlt}
                />
            </>
        )
    }

    // UPLOAD FILE

    const getAllStandard = () => {
        const settingsParams = {
            perPage: 0,
        }
        console.log('StandardManager -> getStandardList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = '/lcs/std'
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('StandardManager -> getStandardList -> Finally : ')
        })
    }

    useEffect(() => {

        console.log('UploadMerchantLast -> useEffect -> whichProduct : ' + JSON.stringify(whichProduct, null, 2));
        setInfoProduct(whichProduct)
        setImageProd(ImageProducts(whichProduct))
        // setImageProdAlt(whichProduct.image.alt)
        getAllStandard()

    }, []);

    const handleDeleteStandard = (whichAction) => {
        console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : ' + whichAction);

        if (whichAction === 'file') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET FILE ');
            setFile(null)
            setFileType(null);
            setFileName(null)
            handleReset(0)

            setDisplayHightHeel(false)
            setAlignment(null)
            setHightHeel(null)
            setStandardName(null)
            setSizeValue(null)
            setWidthValue(null)
        }
        if (whichAction === 'side') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET SIDE');
            setDisplayHightHeel(false)
            setAlignment(null)
            setHightHeel(null)
            setStandardName(null)
            setSizeValue(null)
            setWidthValue(null)
            handleReset(1)
        }
        if (whichAction === 'hightheel') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET HIGHT HEEL');
            setDisplayHightHeel(false)
            setHightHeel(null)
            setStandardName(null)
            setSizeValue(null)
            setWidthValue(null)
            handleReset(2)
        }
        if (whichAction === 'std') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET STANDARD');

            setStandardName(null)
            setSizeValue(null)
            setWidthValue(null)
            handleReset(2)
        }
        if (whichAction === 'size') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET SIZE');
            setSizeValue(null)
            setWidthValue(null)
            handleReset(3)
        }
        if (whichAction === 'width') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET WIDTH');
            setWidthValue(null)
            handleReset(3)
        }
        if (whichAction === 'all') {
            console.log('UploadMerchantLast -> handleDeleteStandard -> whichAction : RESET ALL');
            setFile(null)
            setFileType(null);
            setFileName(null)
            setAlignment(null)
            setDisplayHightHeel(false)
            setHightHeel(null)
            setStandardName(null)
            setSizeValue(null)
            setWidthValue(null)
            handleReset(0)
        }

    }

    const [hoveringItems, setHoveringItems] = useState([]);

    function handleHover(index, isLeaving) {
        setHoveringItems((prevItems) => {
            if (isLeaving) return prevItems.filter((item) => item !== index);
            return [...prevItems, index];
        });
    }

    const linkProducts = () => {
        const merchantID = localStorage.getItem('merchantID')
        console.log('UploadMerchantLast -> linkProducts -> Products params -> merchantID : ' + merchantID)
        const productsParams = {
            id: getInfoProduct.id,
            name: getInfoProduct.title,
            active: true,
            fileName: fileName,
            standard: standardName,
            size: sizeValue,
            sizeWidth: widthValue,
            side: alignment,
            heel: hightHeel,
            variants: getInfoProduct.variants

        }

        const makeProdRequest = `${APIUrl}/merchants/product/add/${merchantID}`
        console.log('UploadMerchantLast -> linkProducts -> Products params : ' + JSON.stringify(productsParams, null, 2))
        APIMainRequestPOST(makeProdRequest, productsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('UploadMerchantLast -> linkProducts -> Products list : ' + JSON.stringify(myJson, null, 2))
        }).finally(() => {
            console.log('UploadMerchantLast -> linkProducts -> Products list -> Finally ->')
        })
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const uploadForm = () => {

        return (
            <>
                <Box sx={{ overflow: 'scroll' }} >

                    <SnackBarManager isOpen={open} getMessage={messageDisplayed} getType={messageTypeDisplayed} />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <IconButton aria-label="settings">
                        <Close onClick={() => { closeModalRefreshList() }} />
                    </IconButton>
                    <Stack direction="column" spacing={1} >
                        <Item sx={{ width: '100%' }}>
                            <Stack direction="row" spacing={1} >
                                {imageProd}
                                <Item sx={{ width: '100%' }}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Add a new last for :
                                    </Typography>
                                    <Typography id="modal-modal-title" variant="h3" component="h2">
                                        <b>{getInfoProduct.title}</b>
                                    </Typography>
                                </Item>
                            </Stack>


                        </Item>
                        <Item sx={{ width: '100%' }}>
                            <form onSubmit={handleSubmit}>

                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                            <StepLabel
                                                optional={
                                                    index === 3 ? (
                                                        <Typography variant="caption">Last step</Typography>
                                                    ) : null
                                                }
                                            >
                                                {
                                                    index === 0 ?
                                                        fileName === null ? step.label :
                                                            <Button variant="outlined" endIcon={<><IconButton
                                                                size="small"
                                                                component="span"
                                                                onClick={() => { handleDeleteStandard('file') }}
                                                                onMouseEnter={() => handleHover(index, true)}
                                                                onMouseLeave={() => handleHover(index, false)}
                                                            >
                                                                {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                            </IconButton></>}
                                                                startIcon={
                                                                    <AttachFile />
                                                                }
                                                            >
                                                                {fileName}
                                                            </Button>
                                                        : null
                                                }
                                                {
                                                    index === 1 ?
                                                        alignment === null ? step.label :
                                                            <Button variant="outlined" endIcon={<><IconButton
                                                                size="small"
                                                                component="span"
                                                                onClick={() => { handleDeleteStandard('side') }}
                                                                onMouseEnter={() => handleHover(index, true)}
                                                                onMouseLeave={() => handleHover(index, false)}
                                                            >
                                                                {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                            </IconButton></>}
                                                                startIcon={
                                                                    alignment === 'left' ? <Avatar src={leftFoot} /> : <Avatar src={rightFoot} />
                                                                }
                                                            >
                                                                {alignment}
                                                            </Button> : null
                                                }
                                                {
                                                    index === 2 ?
                                                        hightHeel === null ? step.label :
                                                            <Button variant="outlined" endIcon={<><IconButton
                                                                size="small"
                                                                component="span"
                                                                onClick={() => { handleDeleteStandard('hightheel') }}
                                                                onMouseEnter={() => handleHover(index, true)}
                                                                onMouseLeave={() => handleHover(index, false)}
                                                            >
                                                                {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                            </IconButton></>}
                                                                startIcon={<Straighten />}
                                                            >
                                                                {hightHeel} cm
                                                            </Button> : null
                                                }
                                                {
                                                    index === 3 ?
                                                        standardName === null ? step.label :
                                                            <Button variant="outlined" endIcon={<><IconButton
                                                                size="small"
                                                                component="span"
                                                                onClick={() => { handleDeleteStandard('std') }}
                                                                onMouseEnter={() => handleHover(index, true)}
                                                                onMouseLeave={() => handleHover(index, false)}
                                                            >
                                                                {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                            </IconButton></>}
                                                                startIcon={<TravelExplore />}
                                                            >
                                                                {standardName}
                                                            </Button> : null
                                                }
                                                {
                                                    index === 4 ?
                                                        sizeValue === null ? step.label :
                                                            <Stack direction="row" spacing={2} >
                                                                <Button variant="outlined" endIcon={<><IconButton
                                                                    size="small"
                                                                    component="span"
                                                                    onClick={() => { handleDeleteStandard('size') }}
                                                                    onMouseEnter={() => handleHover(index, true)}
                                                                    onMouseLeave={() => handleHover(index, false)}
                                                                >
                                                                    {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                                </IconButton></>}
                                                                    startIcon={<Straighten />}
                                                                >
                                                                    {sizeValue}
                                                                </Button>
                                                                {
                                                                    widthValue === null ? null :

                                                                        <Button variant="outlined" endIcon={<><IconButton
                                                                            size="small"
                                                                            component="span"
                                                                            onClick={() => { handleDeleteStandard('width') }}
                                                                            onMouseEnter={() => handleHover(index, true)}
                                                                            onMouseLeave={() => handleHover(index, false)}
                                                                        >
                                                                            {hoveringItems.includes(index) ? <Done sx={{ color: green[700] }} /> : <Close sx={{ color: red[800] }} />}
                                                                        </IconButton></>}
                                                                            startIcon={<Label />}
                                                                        >
                                                                            {widthValue}
                                                                        </Button>
                                                                }

                                                            </Stack> : null
                                                }
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                                <Box sx={{ mb: 2 }}>
                                                    <div>
                                                        {
                                                            index === 0 ?
                                                                <>
                                                                    {
                                                                        file !== null ?
                                                                            <>
                                                                                {file && <ModelViewer file={file} fileType={fileType} />}
                                                                            </> :
                                                                            <>
                                                                                <input id='uploadField' type="file" onChange={handleFileChange} accept='.ply, .stl, .obj' />
                                                                                <br />
                                                                            </>
                                                                    }
                                                                    <Button variant="contained" onClick={handleNext} disabled={file !== null ? false : true} sx={{ mt: 1, mr: 1 }} > Continue </Button>
                                                                </> : null
                                                        }
                                                        {
                                                            index === 1 ?
                                                                <>
                                                                    <ToggleButtonGroup sx={{ color: grey[800] }} value={alignment} exclusive onChange={handleChangeLeftRight} aria-label="Platform">
                                                                        <ToggleButton value="left">
                                                                            <Avatar alt="Remy Sharp" src={leftFoot} />
                                                                            <Typography> Left </Typography>
                                                                        </ToggleButton>
                                                                        <ToggleButton value="right">
                                                                            <Typography> Right </Typography>
                                                                            <Avatar alt="Remy Sharp" src={rightFoot} />
                                                                        </ToggleButton>
                                                                    </ToggleButtonGroup><br />
                                                                    <Button variant="contained" onClick={handleNext} disabled={alignment !== null ? false : true} sx={{ mt: 1, mr: 1 }} > Continue </Button>
                                                                </> : null
                                                        }
                                                        {
                                                            index === 2 ?
                                                                <><br />
                                                                    {
                                                                        displayHightHeel === true ?
                                                                            <TextField
                                                                                value={hightHeel}
                                                                                id="outlined-number"
                                                                                label="Hight heel"
                                                                                type="number"
                                                                                slotProps={{
                                                                                    inputLabel: {
                                                                                        shrink: true,
                                                                                    },
                                                                                }}
                                                                                onChange={getHightHeel}
                                                                                sx={{ width: '100%' }}
                                                                            />
                                                                            : <>

                                                                                <Typography><Radio value="yes" label="Yes"
                                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }}
                                                                                    onChange={getDisplayHightHeel} />
                                                                                    Yes</Typography>
                                                                                <Typography><Radio value="no" label="No"
                                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }}
                                                                                    onChange={getDisplayHightHeel} />
                                                                                    No</Typography>

                                                                            </>
                                                                    }
                                                                    <Button variant="contained" onClick={handleNext} disabled={hightHeel !== null ? false : true} sx={{ mt: 1, mr: 1 }} > Continue </Button>
                                                                </> : null
                                                        }
                                                        {
                                                            index === 3 ?
                                                                <><br />
                                                                    {
                                                                        standardName === null ?
                                                                            <Autocomplete
                                                                                value={standardName}
                                                                                onChange={(event, newValue) => {
                                                                                    getStandardValue(newValue)
                                                                                }}
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={getStandard}
                                                                                renderOption={(props, option) => {
                                                                                    const { key, ...optionProps } = props;
                                                                                    return (
                                                                                        <li key={key} {...optionProps}>
                                                                                            {option.name}
                                                                                        </li>
                                                                                    );
                                                                                }}
                                                                                sx={{ width: '100%' }}
                                                                                renderInput={(params) => <TextField {...params} label="Standard" />}
                                                                            /> : <></>
                                                                    }
                                                                    <Button variant="contained" onClick={handleNext} disabled={standardName !== null ? false : true} sx={{ mt: 1, mr: 1 }} > Continue </Button>
                                                                </> : null
                                                        }
                                                        {
                                                            index === 4 ?
                                                                <><br />
                                                                    <Stack direction="row" spacing={2} >
                                                                        {
                                                                            sizeValue === null ?
                                                                                <Autocomplete
                                                                                    value={sizeValue}
                                                                                    onChange={(event, newValue) => {
                                                                                        setSizeValue(newValue);
                                                                                    }}
                                                                                    disablePortal
                                                                                    id="sizeList"
                                                                                    options={sizeList.map((item) => item.value)}
                                                                                    sx={{ width: '100%' }}
                                                                                    renderInput={(params) => <TextField {...params} label="Size" />}
                                                                                />
                                                                                : <></>
                                                                        }
                                                                        {
                                                                            widthValue === null ?
                                                                                <Autocomplete
                                                                                    value={widthValue}
                                                                                    onChange={(event, newValue) => {
                                                                                        setWidthValue(newValue);
                                                                                    }}
                                                                                    disablePortal
                                                                                    id="widthList"
                                                                                    options={widthList.map((item) => item.name)}
                                                                                    sx={{ width: '100%' }}
                                                                                    renderInput={(params) => <TextField {...params} label="Width" />}
                                                                                /> : <>
                                                                                    <>
                                                                                        <Button
                                                                                            sx={{
                                                                                                mt: 1, mr: 1,
                                                                                                borderColor: blue[800], borderStyle: 'solid',
                                                                                                borderWidth: 1, backgroundColor: grey[50], color: blue[600]
                                                                                            }}
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            size="large">
                                                                                            Send
                                                                                        </Button>
                                                                                    </>
                                                                                </>
                                                                        }
                                                                    </Stack>
                                                                </>
                                                                : <></>
                                                        }

                                                        <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }} > Back </Button>
                                                    </div>
                                                </Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                {activeStep === steps.length && (
                                    <Paper square elevation={0} sx={{ p: 3 }}>
                                        <Typography>All steps completed - you&apos;re finished</Typography>
                                        <Button onClick={handleReset(0)} sx={{ mt: 1, mr: 1 }}>
                                            Reset
                                        </Button>
                                    </Paper>
                                )}
                            </form>
                        </Item>

                    </Stack>
                </Box>


                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openMaxSizeModal}
                    onClose={handleCloseMaxSizeModal}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openMaxSizeModal}>
                        <Box sx={styleMaxSizeModal}>
                            <Close
                                style={{ position: 'absolute', top: '2%', right: '2%', width: '3vw', height: '3vw', color: grey[700] }}
                                onClick={() => { handleCloseMaxSizeModal() }} />

                            <Stack direction="column" spacing={2} sx={{ marginTop: 2 }} >
                                <WarningAmberOutlined sx={{
                                    width: '100%',
                                    height: 65,
                                    color: red[700],
                                }} />
                                <Typography id="transition-modal-title" variant="h3" component="h2">
                                    Attention
                                </Typography>
                                <Typography id="transition-modal-title" variant="body1" component="h2">
                                    {openMessageModal}
                                </Typography>
                                <Button variant="contained" size="large" onClick={handleCloseMaxSizeModal} sx={{ width: '50%', alignSelf: 'center' }}>
                                    OK
                                </Button>

                            </Stack>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )

    }

    return (
        <Box>
            {uploadForm()}
        </Box>
    );
};

const styleMaxSizeModal = {
    position: 'absolute',
    top: '30%',
    left: '40%',
    borderRadius: 4,
    width: '20%',
    height: '30%',
    bgcolor: 'background.paper',
    padding: '1%',
    overflow: 'hidden',
    textAlign: 'center'
};



export default UploadMerchantLast;