import '../Styles/App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, createRef, useRef, useEffect, Component } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { StlViewer } from "react-stl-viewer";
import { styled, useTheme, alpha } from '@mui/material/styles';
import clsx from 'clsx';
import {
    List, ListItem, ListItemButton, ListItemIcon, ListSubheader, ListItemAvatar, TextField, Box, MenuItem, Grid, useMediaQuery,
    FormControl, Button, Stack, Paper, Typography, ListItemText, Divider, Switch, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar,
    Fade, Modal, Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    colors, Collapse, IconButton, Badge
} from '@mui/material';

import {
    SimpleTreeView, TreeItem, RichTreeView, unstable_useTreeItem2, treeItemClasses, TreeViewProvider,
    TreeItem2Content, TreeItem2IconContainer, TreeItem2GroupTransition, TreeItem2Label, TreeItem2Root, TreeItem2Checkbox,
    TreeItem2Icon, TreeItem2Provider, useTreeViewApiRef, TreeItemContent
} from '@mui/x-tree-view'
import { unstable_useTreeItem2 as useTreeItem } from '@mui/x-tree-view/';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';

import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view';

import {
    WarningAmberOutlined, Close, Inbox, Drafts, TaskAltOutlined, CancelOutlined, StarBorder,
    ArrowForwardIosOutlined, FilePresentOutlined, ErrorOutlineRounded, ExpandMore, ExpandLess,
    Delete, Add, Save, Cancel, Edit, Mail, ArrowRight, ArrowDropDown, SupervisorAccount,
    BorderColor, Info, Label, TravelExplore, Straighten, Send, Folder
} from '@mui/icons-material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import SnackBarManager from '../../Components/SnackBarManager';

import Select from '@mui/material/Select';

import { red, green, black, blue, yellow, grey, purple } from '@mui/material/colors';

import { storeData, getData, removeData } from "../Manager/DataManager";
import { APIMainRequestPOST, APIMainRequestGET, APIMainRequestDELETE, APIMainRequestPATCH } from '../Manager/CommManager';
import SnackBarManager from '../Components/SnackBarManager';
import STLViewer from '../Components/STLViewer';
import { bool } from 'prop-types';
import { DateAndTime, DateOnly, TimeOnly } from '../Manager/UnixTimestampToDate';

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: 'var(--tree-view-color)',
    },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: Label,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });

    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0.5,
                            pr: 0,
                        }}
                    >
                        <Box component={Label} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

const StandardManager = () => {

    const apiRef = useTreeViewApiRef();

    const [getStandard, setStandard] = useState([])

    const [getAddSize, setAddSize] = useState(false)
    const [getAddWidth, setAddWidth] = useState(false)
    const [getAddStandard, setAddStandard] = useState(false)

    const [getAddSizeValue, setAddSizeValue] = useState('')
    const [getAddWidthValue, setAddWidthValue] = useState('')
    const [getAddStandardValue, setAddStandardValue] = useState('')

    const getAllStandard = () => {
        const settingsParams = {
            perPage: 0,
        }
        console.log('StandardManager -> getStandardList -> settingsParams : ' + JSON.stringify(settingsParams, null, 2))
        const makeRequest = '/lcs/std'
        APIMainRequestGET(makeRequest, settingsParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('StandardManager -> getStandardList -> Finally : ')


        })
    }

    useEffect(() => {
        console.log('StandardManager -> useEffect');

        getAllStandard()

    }, []);

    const addValue = (setName, whichStd, whichOne) => {

        var initParams = {}
        var initUrl = ''

        if (whichOne === 'std') {
            initParams = { name: setName }
            initUrl = '/lcs/std/'
        }

        if (whichOne === 'size') {
            initParams = { value: setName }
            initUrl = '/lcs/std/size/' + whichStd
        }

        if (whichOne === 'width') {
            initParams = { name: setName }
            initUrl = '/lcs/std/width/' + whichStd
        }

        console.log('StandardManager -> getStandardList -> settingsParams : ' + JSON.stringify(initParams, null, 2))

        APIMainRequestPOST(initUrl, initParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> getStandardList  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('StandardManager -> getStandardList -> Finally : ')
            getAllStandard()
            setAddStandardValue('')
            setAddSizeValue('')
            setAddWidthValue('')
        })
    }

    /// SIZE
    const handleAddSizeElem = (id) => {
        console.log('StandardManager -> handleAddSizeElem -> id : ' + id)
        setAddSize(true)
    }
    const handleOnChangeSizeValue = e => {
        const getValue = e.target.value
        console.log('StandardManager -> handleOnChangeSizeValue -> getValue : ' + getValue)
        setAddSizeValue(getValue)
    }

    /// WIDTH
    const handleAddWidthElem = (id) => {
        console.log('StandardManager -> handleAddWidthElem -> id : ' + id)
        setAddWidth(true)
    }
    const handleOnChangeWidthValue = (e) => {
        const getValue = e.target.value
        console.log('StandardManager -> handleOnChangeWidthValue -> getValue : ' + getValue)
        setAddWidthValue(getValue)
    }

    /// STANDARD
    const handleAddStandardElem = () => {
        console.log('StandardManager -> handleAddStandardElem -> ')
        setAddStandard(true)
    }
    const handleOnChangeStandardValue = (e) => {
        const getValue = e.target.value
        console.log('StandardManager -> handleAddStandardElem -> getValue : ' + getValue)
        setAddStandardValue(getValue)
    }

    const handleUnselectElem = () => {
        getAddSize === true ? setAddSize(false) : setAddSize(false)
        console.log('StandardManager -> handleUnselectElem -> getAddSize : ' + getAddSize)
        getAddWidth === true ? setAddWidth(false) : setAddWidth(false)
        console.log('StandardManager -> handleUnselectElem -> getAddWidth : ' + getAddWidth)
        getAddStandard === true ? setAddStandard(false) : setAddStandard(false)
        console.log('StandardManager -> handleUnselectElem -> getAddStandard : ' + getAddStandard)
    }

    const handleValidateNewElem = (whichOne, whichStd) => {
        console.log('StandardManager -> handleValidateNewElem ')
        handleUnselectElem()
        if (whichOne === 'std') {
            addValue(getAddStandardValue, whichStd, 'std')
        }

        if (whichOne === 'size') {
            addValue(getAddSizeValue, whichStd, 'size')
        }

        if (whichOne === 'width') {
            addValue(getAddWidthValue, whichStd, 'width')
        }
    }

    const deleteValue = (whichItem, whichStd, whichOne) => {
        console.log('StandardManager -> deleteValue -> whichItem : ' + whichItem + ' - whichStd : ' + whichStd + ' - whichOne : ' + whichOne)
        var initParams = {}
        var initUrl = ''

        if (whichOne === 'std') { initUrl = '/lcs/std/' + whichStd }

        if (whichOne === 'size') { initUrl = '/lcs/std/size/' + whichStd + '/' + whichItem }

        if (whichOne === 'width') { initUrl = '/lcs/std/width/' + whichStd + '/' + whichItem }

        console.log('StandardManager -> deleteValue -> settingsParams : ' + JSON.stringify(initParams, null, 2))
        initParams = { value: 0 }
        APIMainRequestDELETE(initUrl, initParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> deleteValue  : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('StandardManager -> deleteValue -> Finally : ')
            getAllStandard()

        })
    }

    const [getUpdateSize, setUpdateSize] = useState(0)
    const [getUpdateWidth, setUpdateWidth] = useState(0)
    const [getUpdateStandard, setUpdateStandard] = useState(0)

    const handleUnselectUpdateElem = () => {
        getUpdateSize !== 0 ? setUpdateSize(0) : setUpdateSize(0)
        console.log('StandardManager -> handleUnselectUpdateElem -> getUpdateSize : ' + getUpdateSize)
        getUpdateWidth !== 0 ? setUpdateWidth(0) : setUpdateWidth(0)
        console.log('StandardManager -> handleUnselectUpdateElem -> getUpdateWidth : ' + getUpdateWidth)
        getUpdateStandard !== 0 ? setUpdateStandard(0) : setUpdateStandard(0)
        console.log('StandardManager -> handleUnselectUpdateElem -> getUpdateStandard : ' + getUpdateStandard)
        setAddStandardValue('')
        setAddSizeValue('')
        setAddWidthValue('')
    }

    const handleValidateUpdateElem = (whichOne, whichStd, whichElem) => {
        console.log('StandardManager -> handleValidateNewElem ')
        handleUnselectElem()
        if (whichOne === 'std') {
            updateValue(whichElem, whichStd, 'std', getAddStandardValue)
        }

        if (whichOne === 'size') {
            updateValue(whichElem, whichStd, 'size', getAddSizeValue)
        }

        if (whichOne === 'width') {
            updateValue(whichElem, whichStd, 'width', getAddWidthValue)
        }
    }

    const updateValue = (whichItem, whichStd, whichOne, newValue) => {
        console.log('StandardManager -> updateValue -> whichItem : ' + whichItem + ' - whichStd : ' + whichStd + ' - whichOne : ' + whichOne)
        console.log('StandardManager -> updateValue -> newValue : ' + newValue)
        var initParams = {}
        var initUrl = ''

        if (whichOne === 'std') {
            initParams = { name: newValue }
            initUrl = '/lcs/std/' + whichStd
        }

        if (whichOne === 'size') {
            initParams = { value: newValue }
            initUrl = '/lcs/std/size/' + whichStd + '/' + whichItem
        }

        if (whichOne === 'width') {
            initParams = { name: newValue }
            initUrl = '/lcs/std/width/' + whichStd + '/' + whichItem
        }

        console.log('StandardManager -> updateValue -> settingsParams : ' + JSON.stringify(initParams, null, 2))

        APIMainRequestPATCH(initUrl, initParams).then(function (response) {
            return response.getData
        }).then(function (myJson) {
            console.log('StandardManager -> updateValue : ' + JSON.stringify(myJson, null, 2))

            setStandard(myJson)

        }).finally(() => {
            console.log('StandardManager -> updateValue -> Finally : ')
            getAllStandard()
            handleUnselectUpdateElem()


        })

    }


    const handleItemSelectionToggle = (e, id, IDItem) => {
        console.log('StandardManager -> handleItemSelectionToggle -> id : ' + id + ' - IDItem : ' + IDItem)

        setUpdateStandard(IDItem)
        setUpdateSize(IDItem)
        setUpdateWidth(IDItem)
        // apiRef.current.setItemExpansion(event, 'grid', true);
        //apiRef.current.setItemExpansion(e, id, true)
    };



    const [openStd, setOpenStd] = React.useState(null);
    const handleClickStd = (id) => {
        console.log('StandardManager -> handleClickStd -> id : ' + id)
        setOpenStd(id);
    };

    const [openSize, setOpenSize] = React.useState(null);
    const handleClickSize = (id) => {
        console.log('StandardManager -> handleClickSize -> id : ' + id)
        setOpenSize(id);
    };

    const [openWidth, setOpenWidth] = React.useState(null);
    const handleClickWidth = (id) => {
        console.log('StandardManager -> handleClickWidth -> id : ' + id)
        setOpenWidth(id);
    };

    return (
        <Box sx={{ minHeight: 352 }}>

            <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    getAddStandard === true ?
                        <>
                            <TextField autoFocus
                                value={getAddStandardValue}
                                onChange={handleOnChangeStandardValue}
                                label='New standard'
                                id="outlined-size-small"
                                variant='outlined'
                                size="small"
                                color='primary'
                                sx={{ margin: 1 }} />
                            <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('std', '')} sx={{ margin: 1 }}>Add</Button>
                            <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                        </>
                        :
                        <><br />
                            <Stack direction="row" spacing={1} >

                                <Badge color="error" badgeContent={getStandard.length}>
                                    <Button variant="outlined" startIcon={<Add />} onClick={() => handleAddStandardElem()} size="small" sx={{ color: blue[700] }}>
                                        Standard
                                    </Button>
                                </Badge>

                            </Stack>
                        </>
                }
            >
                {

                    getStandard.map((item, index) =>
                        <>
                            <ListItem
                                secondaryAction={
                                    getUpdateStandard !== item._id ?
                                        <>
                                            <Stack direction="row" spacing={2} >
                                                <IconButton>
                                                    <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index, item._id)} />
                                                </IconButton>
                                                <IconButton>
                                                    <Delete sx={{ color: red[700] }} onClick={() => deleteValue('', item._id, 'std')} />
                                                </IconButton>

                                                {openStd !== index ? <IconButton><ExpandMore onClick={() => handleClickStd(index)} /></IconButton> : <IconButton><ExpandLess onClick={() => handleClickStd(null)} /></IconButton>}
                                            </Stack>
                                        </> : <></>
                                }
                            >
                                <ListItemAvatar><Avatar><TravelExplore /></Avatar></ListItemAvatar>
                                <ListItemText
                                    primary={
                                        getUpdateStandard !== item._id ? item.name :
                                            <>
                                                <TextField autoFocus
                                                    value={getAddStandardValue}
                                                    onChange={handleOnChangeStandardValue}
                                                    label='Update standard'
                                                    id="outlined-size-small"
                                                    variant='outlined'
                                                    size="small"
                                                    color='primary'
                                                    sx={{ margin: 1 }} />
                                                <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('std', item._id, item._id)} sx={{ margin: 1 }}>Update</Button>
                                                <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                            </>
                                    }
                                    secondary={getUpdateStandard !== item._id ? <>Created : <DateOnly unixTimestamp={item.entryDate} /> - Updated : <DateOnly unixTimestamp={item.updateDate} /></> : ''}
                                />

                            </ListItem>
                            <Collapse in={openStd === index} timeout="auto" unmountOnExit sx={{ paddingLeft: 4 }}>
                                <Stack direction="column" spacing={1} >
                                    <ListItem
                                        secondaryAction={
                                            getAddSize === true ?
                                                <>
                                                    <TextField autoFocus
                                                        value={getAddSizeValue}
                                                        onChange={handleOnChangeSizeValue}
                                                        label='New size'
                                                        id="outlined-size-small"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        sx={{ margin: 1 }} />
                                                    <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('size', item._id)} sx={{ margin: 1 }}>Add</Button>
                                                    <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                </>
                                                :
                                                <>
                                                    <Stack direction="row" spacing={1} >
                                                        {openSize !== index + 1 ? <IconButton><ExpandMore onClick={() => handleClickSize(index + 1)} /></IconButton> : <IconButton><ExpandLess onClick={() => handleClickSize(null)} /></IconButton>}
                                                    </Stack>
                                                </>}
                                    >
                                        <Stack direction="row" spacing={1} >
                                            <Straighten />
                                            {
                                                getAddSize === true ? <></> :
                                                    <Badge color="error" badgeContent={item.size.length}>
                                                        <Button variant="outlined" startIcon={<><Add /></>} onClick={() => handleAddSizeElem(item._id)} size="small" sx={{ color: blue[700] }}>
                                                            Size
                                                        </Button>
                                                    </Badge>
                                            }
                                        </Stack>

                                    </ListItem>
                                    <Collapse in={openSize === index + 1} timeout="auto" unmountOnExit sx={{ paddingLeft: 4 }}>
                                        {
                                            item.size.map((sizeItem, sizeIndex) =>
                                                <>
                                                    <ListItem
                                                        secondaryAction={

                                                            getUpdateSize !== sizeItem._id ?
                                                                <Stack direction="row" spacing={2} >
                                                                    <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index, sizeItem._id)} />
                                                                    <Delete sx={{ color: red[700] }} onClick={() => deleteValue(sizeItem._id, item._id, 'size')} />
                                                                </Stack>
                                                                :
                                                                <>
                                                                    <TextField autoFocus
                                                                        value={getAddSizeValue}
                                                                        onChange={handleOnChangeSizeValue}
                                                                        label={'Update size : ' + sizeItem.value}
                                                                        id="outlined-size-small"
                                                                        variant='outlined'
                                                                        size="small"
                                                                        color='primary'
                                                                        sx={{ margin: 1 }} />
                                                                    <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('size', item._id, sizeItem._id)} sx={{ margin: 1 }}>Update</Button>
                                                                    <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                                </>
                                                        }
                                                    >
                                                        {
                                                            getUpdateSize !== sizeItem._id ?
                                                                <Stack direction="row" spacing={2} >
                                                                    <Straighten />
                                                                    <ListItemText
                                                                        primary={sizeItem.value}
                                                                        secondary={<>Created : <DateOnly unixTimestamp={sizeItem.entryDate} /> - Updated : <DateOnly unixTimestamp={sizeItem.updateDate} /></>}
                                                                    />
                                                                </Stack> : <Stack direction="row" spacing={2} sx={{ margin: 3 }} ></Stack>
                                                        }

                                                    </ListItem>
                                                </>
                                            )
                                        }
                                    </Collapse>


                                    <ListItem
                                        secondaryAction={
                                            getAddWidth === true ?
                                                <>
                                                    <TextField autoFocus
                                                        value={getAddWidthValue}
                                                        onChange={handleOnChangeWidthValue}
                                                        label='New width'
                                                        id="outlined-size-small"
                                                        variant='outlined'
                                                        size="small"
                                                        color='primary'
                                                        sx={{ margin: 1 }} />
                                                    <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('width', item._id)} sx={{ margin: 1 }}>Add</Button>
                                                    <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                </>
                                                :
                                                <>
                                                    <Stack direction="row" spacing={1} >
                                                        {openWidth !== index + 2 ? <IconButton><ExpandMore onClick={() => handleClickWidth(index + 2)} /></IconButton> : <IconButton><ExpandLess onClick={() => handleClickWidth(null)} /></IconButton>}

                                                    </Stack>
                                                </>}
                                    >
                                        <Stack direction="row" spacing={1} >
                                        <Label />
                                            {
                                                getAddWidth === true ? <></> :
                                                    <Badge color="error" badgeContent={item.width.length}>
                                                        <Button variant="outlined" startIcon={<><Add /></>} onClick={() => handleAddWidthElem(item._id)} size="small" sx={{ color: blue[700] }}>
                                                        Width
                                                        </Button>
                                                    </Badge>
                                            }
                                        </Stack>

                                    </ListItem>
                                    <Collapse in={openWidth === index + 2} timeout="auto" unmountOnExit sx={{ paddingLeft: 4 }}>
                                        {
                                            item.width.map((widthItem, sizeIndex) =>
                                                <>
                                                    <ListItem
                                                        secondaryAction={

                                                            getUpdateWidth !== widthItem._id ?
                                                                <Stack direction="row" spacing={2} >
                                                                    <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index, widthItem._id)} />
                                                                    <Delete sx={{ color: red[700] }} onClick={() => deleteValue(widthItem._id, item._id, 'width')} />
                                                                </Stack>
                                                                :
                                                                <>
                                                                    <TextField autoFocus
                                                                        value={getAddWidthValue}
                                                                        onChange={handleOnChangeWidthValue}
                                                                        label={'Update size : ' + widthItem.name}
                                                                        id="outlined-size-small"
                                                                        variant='outlined'
                                                                        size="small"
                                                                        color='primary'
                                                                        sx={{ margin: 1 }} />
                                                                    <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('width', item._id, widthItem._id)} sx={{ margin: 1 }}>Update</Button>
                                                                    <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                                </>
                                                        }
                                                    >
                                                        {
                                                            getUpdateWidth !== widthItem._id ?
                                                                <Stack direction="row" spacing={2} >
                                                                    <Label />
                                                                    <ListItemText
                                                                        primary={widthItem.name}
                                                                        secondary={<>Created : <DateOnly unixTimestamp={widthItem.entryDate} /> - Updated : <DateOnly unixTimestamp={widthItem.updateDate} /></>}
                                                                    />
                                                                </Stack> : <Stack direction="row" spacing={2} sx={{ margin: 3 }} ></Stack>
                                                        }
                                                    </ListItem>
                                                </>
                                            )
                                        }
                                    </Collapse>

                                </Stack>
                            </Collapse>

                        </>
                    )
                }
            </List>










            {/* <SimpleTreeView
                aria-label={"Standard"}
                // defaultExpandedItems={['3']}
                // defaultSelectedItems="5"
                slots={{
                    expandIcon: ArrowRight,
                    collapseIcon: ArrowDropDown,
                    endIcon: EndIcon,
                }}
                sx={{ flexGrow: 1 }}
                expansionTrigger="iconContainer"
                apiRef={apiRef}
            >
                {
                    getAddStandard === true ?
                        <>

                            <TextField autoFocus
                                value={getAddStandardValue}
                                onChange={handleOnChangeStandardValue}
                                label='New standard'
                                id="outlined-size-small"
                                variant='outlined'
                                size="small"
                                color='primary'
                                sx={{ margin: 1 }} />
                            <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('std', '')} sx={{ margin: 1 }}>Add</Button>
                            <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                        </>
                        :
                        <>
                            <Stack direction="row" spacing={1} >
                                <Typography variant="h6">{getStandard.length}</Typography>
                                <Button onClick={() => handleAddStandardElem()} size="small" sx={{ color: grey[500] }} ><Add /> New standard</Button>
                            </Stack>
                        </>
                }
                {
                    getStandard.map((item, index) =>
                        <>
                            <CustomTreeItem itemId={item._id} label={
                                getUpdateStandard !== item._id ? item.name :
                                    <>
                                        <TextField autoFocus
                                            value={getAddStandardValue}
                                            onChange={handleOnChangeStandardValue}
                                            label='New standard'
                                            id="outlined-size-small"
                                            variant='outlined'
                                            size="small"
                                            color='primary'
                                            sx={{ margin: 1 }} />
                                        <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('std', item._id, item._id)} sx={{ margin: 1 }}>Update</Button>
                                        <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                    </>
                            }
                                labelIcon={TravelExplore}
                                labelInfo={
                                    <>
                                        <Stack direction="row" spacing={2} >
                                            <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index, item._id)} />
                                            <Delete sx={{ color: red[700] }} onClick={() => deleteValue('', item._id, 'std')} />
                                        </Stack>

                                    </>
                                }
                            >
                                <CustomTreeItem itemId={index} label="Sizes" labelIcon={Straighten} labelInfo={
                                    getAddSize === true ?
                                        <>
                                            <TextField autoFocus
                                                value={getAddSizeValue}
                                                onChange={handleOnChangeSizeValue}
                                                label='New size'
                                                id="outlined-size-small"
                                                variant='outlined'
                                                size="small"
                                                color='primary'
                                                sx={{ margin: 1 }} />
                                            <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('size', item._id)} sx={{ margin: 1 }}>Add</Button>
                                            <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                        </>
                                        :
                                        <>
                                            <Stack direction="row" spacing={1} >
                                                <Typography variant="h6">{item.size.length}</Typography>
                                                <Button onClick={() => handleAddSizeElem(item._id)} size="small" sx={{ color: grey[500] }} ><Add /> New size</Button>
                                            </Stack>
                                        </>}
                                >

                                    {
                                        item.size.map((sizeItem, sizeIndex) =>
                                            <>
                                                <CustomTreeItem
                                                    itemId={RandomID(9)}
                                                    label={
                                                        getUpdateSize !== sizeItem._id ? sizeItem.value :
                                                            <>
                                                                <TextField autoFocus
                                                                    value={getAddSizeValue}
                                                                    onChange={handleOnChangeSizeValue}
                                                                    label='New size'
                                                                    id="outlined-size-small"
                                                                    variant='outlined'
                                                                    size="small"
                                                                    color='primary'
                                                                    sx={{ margin: 1 }} />
                                                                <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('size', item._id, sizeItem._id)} sx={{ margin: 1 }}>Update</Button>
                                                                <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                            </>
                                                    }
                                                    labelIcon={Straighten}
                                                    labelInfo={
                                                        <>
                                                            <Stack direction="row" spacing={2} >
                                                                <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index, sizeItem._id)} />
                                                                <Delete sx={{ color: red[700] }} onClick={() => deleteValue(sizeItem._id, item._id, 'size')} />
                                                            </Stack>
                                                        </>
                                                    }
                                                    color="#1a73e8"
                                                    bgColor="#e8f0fe"
                                                    colorForDarkMode="#B8E7FB"
                                                    bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                                                />
                                            </>
                                        )
                                    }
                                </CustomTreeItem>

                                <CustomTreeItem itemId={index + 1} label="Width" labelIcon={Label} labelInfo={
                                    getAddWidth === true ?
                                        <>

                                            <TextField autoFocus
                                                value={getAddWidthValue}
                                                onChange={handleOnChangeWidthValue}
                                                label='New width'
                                                id="outlined-size-small"
                                                variant='outlined'
                                                size="small"
                                                color='primary'
                                                sx={{ margin: 1 }} />
                                            <Button variant="outlined" color='primary' onClick={() => handleValidateNewElem('width', item._id)} sx={{ margin: 1 }}>Add</Button>
                                            <Button variant="outlined" color="error" onClick={() => { handleUnselectElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                        </>
                                        :
                                        <>
                                            <Stack direction="row" spacing={1} >
                                                <Typography variant="h6">{item.width.length}</Typography>
                                                <Button onClick={() => handleAddWidthElem(item._id)} size="small" sx={{ color: grey[500] }} ><Add /> New width</Button>
                                            </Stack>
                                        </>
                                }>
                                    {
                                        item.width.map((widthItem, sizeIndex) =>
                                            <>
                                                <CustomTreeItem //getUpdateWidth
                                                    itemId={RandomID(9)}
                                                    label={
                                                        getUpdateWidth !== widthItem._id ? widthItem.name :
                                                            <>
                                                                <TextField autoFocus
                                                                    value={getAddWidthValue}
                                                                    onChange={handleOnChangeWidthValue}
                                                                    label='New width'
                                                                    id="outlined-size-small"
                                                                    variant='outlined'
                                                                    size="small"
                                                                    color='primary'
                                                                    sx={{ margin: 1 }} />
                                                                <Button variant="outlined" color='primary' onClick={() => handleValidateUpdateElem('width', item._id, widthItem._id)} sx={{ margin: 1 }}>Update</Button>
                                                                <Button variant="outlined" color="error" onClick={() => { handleUnselectUpdateElem() }} sx={{ margin: 1 }}>Cancel</Button>
                                                            </>
                                                    }
                                                    labelIcon={Straighten}
                                                    labelInfo={
                                                        <>
                                                            <Stack direction="row" spacing={2} >
                                                                <Edit sx={{ color: blue[600] }} onClick={(e) => handleItemSelectionToggle(e, index + 1, widthItem._id)} />
                                                                <Delete sx={{ color: red[700] }} onClick={() => deleteValue(widthItem._id, item._id, 'width')} />
                                                            </Stack>
                                                        </>
                                                    }
                                                    color="#e3742f"
                                                    bgColor="#fcefe3"
                                                    colorForDarkMode="#FFE2B7"
                                                    bgColorForDarkMode={alpha('#ff8f00', 0.2)}
                                                />
                                            </>
                                        )
                                    }
                                </CustomTreeItem>
                            </CustomTreeItem >
                        </>
                    )
                }
            </SimpleTreeView> */}
        </Box >
    );
}

export default StandardManager;